/* JS */

/* react */
import React from 'react';
/* third part components */

/* custom components */
import CaseStudyPage from './CaseStudyPage';

var gsap;
var detect = require('is-client');
if (detect()) {
	gsap = require('gsap');
	let sctr = require('gsap/ScrollTrigger');
	gsap.gsap.registerPlugin(sctr);
}

const QuinaryoPage = props => {
	return (
		<CaseStudyPage
			title="Branding, Progettazione web - Quinaryo"
			relPath="quinaryo"
			description="Quinaryo è una piattaforma rivoluzionaria attraverso la quale sarà possibile vendere o acquistare in criptovalute titoli di
		società non quotate sui mercati tradizionali."
		>
		</CaseStudyPage>
	);
};

export default QuinaryoPage;
